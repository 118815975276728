import request1 from '../../util/loginrequest'

// 1.注册接口
export const topay = (params) => {
  return request1({

    url: '/api/demo/WxPay/weChat_page_pay',
    method: 'post',
    header: {
      'content-type': 'application/json'
    },
    data: {
      user_id: params.id || ''
      //
    }

  })
}
// 1.注册接口
export const topay1 = (params) => {
  return request1({

    url: '/api/demo/WxPayNew/weChat_page_pay',
    method: 'post',
    header: {
      'content-type': 'application/json'
    },
    data: {
      user_id: params.id || '',
      money: params.money || '',
      time: params.time || ''
      //
    }

  })
}
// 科研项目限制数量

export const VipRestrict = (params) => {
  return request1({

    url: '/api/demo/VipRestrict/project',
    method: 'post',
    header: {
      'content-type': 'application/json'
    },
    data: {
      id: params.id || ''
      //
    }

  })
}
// 政采招标限制数量

export const VipRestricttender = (params) => {
  return request1({

    url: '/api/demo/VipRestrict/tender',
    method: 'post',
    header: {
      'content-type': 'application/json'
    },
    data: {
      id: params.id || ''
      //
    }

  })
}

// 分享海报

export const shareHai = (params) => {
  return request1({

    url: '/api/demo/subscribe/shareHai',
    method: 'post',
    header: {
      'content-type': 'application/json'
    },
    data: {
      id: params.id || ''
      //
    }

  })
}

// 申报指南限制数量

export const VipRestrictprojectGov = (params) => {
  return request1({

    url: '/api/demo/VipRestrict/projectGov',
    method: 'post',
    header: {
      'content-type': 'application/json'
    },
    data: {
      id: params.id || ''
      //
    }

  })
}
// 点击量

export const Tenderhits = (params) => {
  return request1({

    url: '/api/demo/Tender/hits',
    method: 'post',
    header: {
      'content-type': 'application/json'
    },
    data: {
      id: params.id || ''
      //
    }

  })
}
// 申报指南点击量

export const ProjectGovhits = (params) => {
  return request1({

    url: 'api/demo/ProjectGov/hits',
    method: 'post',
    header: {
      'content-type': 'application/json'
    },
    data: {
      id: params.id || ''
      //
    }

  })
}
// 科研项目点击量

export const Projecthits = (params) => {
  return request1({

    url: 'api/demo/Project/hits',
    method: 'post',
    header: {
      'content-type': 'application/json'
    },
    data: {
      id: params.id || ''
      //
    }

  })
}
// 深度文章点击量

export const Articlesthits = (params) => {
  return request1({

    url: 'api/demo/Articles/hits',
    method: 'post',
    header: {
      'content-type': 'application/json'
    },
    data: {
      id: params.id || ''
      //
    }

  })
}

// 是否支付成功

export const ispaysuccess = (params) => {
  return request1({

    url: '/api/demo/WxPay/order_find',
    method: 'post',
    header: {
      'content-type': 'application/json'
    },
    data: {
      order_id: params.order_id || ''
      //
    }

  })
}
// 钱

export const z_mony = (params) => {
  return request1({

    url: '/api/demo/Index/money',
    method: 'post',
    header: {
      'content-type': 'application/json'
    },
    data: {
      id: params.id || ''
      //
    }

  })
}

export const z_mony1 = () => {
  return request1({

    url: '/api/demo/hsj/money',
    method: 'get',
    header: {
      'content-type': 'application/json'
    },
    data: {
    }

  })
}