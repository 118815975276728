import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    islogin: false,
    userid: '',
    ispro: false,
    navActive: 1,
    // 微信申报指南基础路径
    wxreportbaseurl: 'https://keyanpro.com/?id=2',
    // 微信政采招标基础路径
    wxTenderbaseurl: 'https://keyanpro.com/?id=3',
    // 微信科研项目基础路径
    wxprojectbaseurl: 'https://keyanpro.com/?id=4',
    // 微信科研项目基础路径
    wxArticlebaseurl: 'https://keyanpro.com/?id=5',
    // h5基础路劲
    h5baseurl: 'https://keyanpro.com/?id=1',
    // 缩放比列
    suofangbi: '',
    gundong:false,
    ketileixing: '',
    serach111:'',
    pageNo:1,
    vip_time_new:"0",
    switchtab:1
  },
  mutations: {
    setLoginStatus (state, data) {
      state.islogin = data
    },
    setsuofangbi (state, data) {
      state.suofangbi = data
    },
    setUserid (state, data) {
      state.userid = data
    },
    setispro (state, data) {
      state.ispro = data
    },
    setnavActive (state, data) {
      state.navActive = data
    },
    seth5baseurl (state, data) {
      state.h5baseurl = data
    },
    setgundong(state,data){
      state.gundong = data
    },
    setketileixing (state, data) {
      state.ketileixing = data
    },
    setserach111 (state, data) {
      state.serach111 = data
    },
    setpageNo (state, data) {
      state.pageNo = data
    },
    setvip_time_new (state, data) {
      state.vip_time_new = data
    },
    setswitchtab (state, data) {
      state.switchtab = data
    },
    

  },
  actions: {
  },
  modules: {
  }
})

export default store
