import store from '../store'
(function (doc, win) {
  var docEl = doc.documentElement

  var resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize'

  var recalc = function () {
    var clientWidth = docEl.clientWidth

    if (!clientWidth) return

    docEl.style.fontSize = 100 * (clientWidth / 1920) + 'px'
    // eslint-disable-next-line no-undef
    store.commit('setsuofangbi', clientWidth / 1920)
    console.log(clientWidth / 1920)

    // console.log(docEl.style.fontSize)
  }

  if (!doc.addEventListener) return

  win.addEventListener(resizeEvt, recalc, false)

  doc.addEventListener('DOMContentLoaded', recalc, false)
})(document, window)
