import request from '../../util/loginrequest'
// 1.验证码登录接口
export const toLogined1 = (params) => {
  return request({

    url: '/api/demo/Register/codeLogin',
    method: 'post',
    header: {
      'content-type': 'application/json'
    },
    data: {
      mobile: params.mobile || '',
      code: params.code || ''
    }

  })
}
// 1.登录接口
export const toLogined = (params) => {
  return request({

    url: '/api/demo/Register/login',
    method: 'post',
    header: {
      'content-type': 'application/json'
    },
    data: {
      user_login: params.user_login || '',
      user_pass: params.user_pass || ''
    }

  })
}
// 获取短信验证码
export const getSMScode = (params) => {
  return request({
    url: '/api/demo/Register/aliyun',
    method: 'post',
    data: {
      phone: params
    }

  })
}
// 获取用户是否为pro会员
export const getProuser = (params) => {
  return request({
    url: '/api/demo/WxPay/user_pay',
    method: 'post',
    data: {
      user_id: params.user_id || ''
    }

  })
}
