import Vue from 'vue'
import VueRouter from 'vue-router'
import { getProuser } from '../api/login/login'
// eslint-disable-next-line no-unused-vars
import { Articlesthits, ProjectGovhits, Projecthits, Tenderhits, VipRestrict, VipRestrictprojectGov, VipRestricttender } from '../api/vip/vip'
Vue.use(VueRouter)

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: '/',
    redirect: 'pc/content'
  },
  {
    path: '/pc',
    name: 'pc',
    component: () => import('../views/pc/index.vue'),
    redirect: 'pc/content',
    children: [
      {
        path: 'content',
        redirect: 'content/homePage',
        component: () => import('../views/pc/content.vue'),
        children: [
          {
            path: 'homePage',
            meta:{
              title:"首页 ｜ 科研人 - 科研大数据平台，发现更多课题机会"
            },
            component: () => import('../components/pc/index/homePage.vue')
          },
          {
            path: 'reportingGuidelines',
            component: () => import('../components/pc/index/reportingGuidelines.vue'),
            meta: {
              title: '申报指南 ｜ 科研人 - 科研大数据平台，发现更多课题机会'
            }
          },
          {
            path: 'reportingGuidelinesDetails:id',
            name: 'reportingGuidelinesDetails',
            component: () => import('../components/pc/index/details/reportingGuidelinesDetails.vue')
          },
          {
            path: 'politicsInTheTender',
            component: () => import('../components/pc/index/politicsInTheTender.vue'),
            meta: {
              title: '政采招标 ｜ 科研人 - 科研大数据平台，发现更多课题机会'
            }
          },
          {
            path: 'politicsInTheTenderDetails:id',
            name: 'politicsInTheTenderDetails',
            component: () => import('../components/pc/index/details/politicsInTheTenderDetails.vue')
          },
          {
            path: 'scientificResearchProject',
            component: () => import('../components/pc/index/scientificResearchProject.vue'),
            meta: {
              title: '科研项目 ｜ 科研人 - 科研大数据平台，发现更多课题机会'
            }
          },
          {
            path: 'scientificResearchProjectDetails:id',
            name: 'scientificResearchProjectDetails',
            component: () => import('../components/pc/index/details/scientificResearchProjectDetails.vue')
          },
          {
            path: 'depthOfTheArticle',
            component: () => import('../components/pc/index/depthOfTheArticle.vue'),
            meta: {
              title: '深度文章 ｜ 科研人 - 科研大数据平台，发现更多课题机会'
            }

          },
          {
            path: 'depthOfTheArticleDetails:id',
            component: () => import('../components/pc/index/details/depthOfTheArticleDetails.vue')
          },
          {
            path: 'mySubscription',
            meta: {
              title: '我的订阅 ｜ 科研人 - 科研大数据平台，发现更多课题机会'
            },
            component: () => import('../components/pc/index/mySubscription.vue')
          },
          {
            path: 'aboutUs',
            component: () => import('../components/pc/index/aboutUs.vue')
          },
          {
            path: 'suggest',
            component: () => import('../components/pc/index/suggest.vue')
          },
          {
            path: 'declarationAndTerms',
            meta:{
              title:"科研人 - 科研大数据平台，发现更多课题机会"
            },
            component: () => import('../components/pc/index/declarationAndTerms.vue')
          },
          {
            path: 'myCollectionarticles',
            meta: {
              title: '我的收藏 ｜ 科研人 - 科研大数据平台，发现更多课题机会'
            },
            component: () => import('../components/pc/index/myCollectionarticles.vue')
          },
          {
            path: 'myRecentInformation',
            meta: {
              title: '近期浏览 ｜ 科研人 - 科研大数据平台，发现更多课题机会'
            },
            component: () => import('../components/pc/index/myRecentInformation.vue')
          },
          {
            path: 'myHaibao',
            meta: {
              title: '电子发票 ｜ 科研人 - 科研大数据平台，发现更多课题机会'
            },
            component: () => import('../components/pc/index/myHaibao.vue')
          }
        ]
      },
      {
        path: 'loginroot',
        redirect: 'loginroot/login',
        component: () => import('../views/pc/login.vue'),
        children: [
          {
            path: 'login',
            meta:{
              title:"科研人 - 科研大数据平台，发现更多课题机会"
            },
            component: () => import('../components/pc/login/login.vue')
          },
          {
            path: 'registered',
            meta:{
              title:"科研人 - 科研大数据平台，发现更多课题机会"
            },
            component: () => import('../components/pc/login/registered.vue')
          },
          {
            path: 'resetpassword',
            meta:{
              title:"科研人 - 科研大数据平台，发现更多课题机会"
            },
            component: () => import('../components/pc/login/resetpassword.vue')
          }

        ]
      },
      {
        path: 'viproot',
        redirect: 'viproot/vip',
        component: () => import('../views/pc/vip.vue'),
        children: [
          {
            path: 'vip',
            component: () => import('../components/pc/vip/vip.vue')
          }

        ]
      }

    ]
  },
  {
    path: '/mobile',
    name: 'mobile',
    component: () => import('../views/mobile/index.vue')
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, _from, next) => {
  let userid = ''
  if (localStorage.getItem('usermessage')) {
    userid = JSON.parse(localStorage.getItem('usermessage')).id
  } else {

  }

  getProuser({ user_id: userid }).then(res => {
    console.log('用户是否为pro会员', res)
    // eslint-disable-next-line eqeqeq
    router.app.$options.store.commit('setvip_time_new', res.data.vip_time_new)
    if (res.data && res.data.level && res.data.level == 3) {
      localStorage.setItem('ispro', 'true')
      router.app.$options.store.commit('setispro', 'true')
    } else {
      localStorage.setItem('ispro', 'false')
      router.app.$options.store.commit('setispro', 'false')
    }
  }, err => {
    console.log('获取会员信息异常', err)
  })
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.meta.content) {
    const head = document.getElementsByTagName('head')
    const meta = document.createElement('meta')
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content
    head[0].appendChild(meta)
  }
  console.log(to)
  if (to.path === '/pc/content/mySubscription') {
    if (localStorage.getItem('usermessage')) {
      next()
    } else {
      next({
        path: '/pc/loginroot/login'
      })
    }
  } else if (to.path.indexOf('/pc/content/reportingGuidelinesDetails') !== -1) {
    ProjectGovhits({ id: userid }).then(res1 => {
      console.log('chenggong')
    }, err1 => {
      console.log('shibai')
    })
    if (localStorage.getItem('usermessage')) {
      VipRestrictprojectGov({ id: userid }).then(
        _res => {
          console.log('成功', _res)
          if (_res.code === 1) {
            next()
          } else {
            // router.app.$options.$message
            router.app.$message({
              message: _res.msg,
              type: 'warning'
            })
          }
        }, _err => {
          console.log('失败')
          next()
        }
      )
    } else {
      next({
        path: '/pc/loginroot/login'
      })
    }
  } else if (to.path.indexOf('/pc/content/reportingGuidelines') !== -1) {
    if (localStorage.getItem('usermessage')) {
      next()
    } else {
      next({
        path: '/pc/loginroot/login'
      })
    }
  } else if (to.path.indexOf('/pc/content/myCollectionarticles') !== -1) {
    if (localStorage.getItem('usermessage')) {
      next()
    } else {
      next({
        path: '/pc/loginroot/login'
      })
    }
  } else if (to.path.indexOf('/pc/content/myRecentInformation') !== -1) {
    if (localStorage.getItem('usermessage')) {
      next()
    } else {
      next({
        path: '/pc/loginroot/login'
      })
    }
    // eslint-disable-next-line eqeqeq
  } else if (to.path == '/pc/content/politicsInTheTender') {
    if (localStorage.getItem('usermessage')) {
      next()
    } else {
      next({
        path: '/pc/loginroot/login'
      })
    }
    // eslint-disable-next-line eqeqeq
  } else if (to.path == '/pc/content/scientificResearchProject') {
    if (localStorage.getItem('usermessage')) {
      next()
    } else {
      next({
        path: '/pc/loginroot/login'
      })
    }
  } else if (to.path.indexOf('/pc/content/politicsInTheTenderDetails') !== -1) {
    Tenderhits({ id: userid }).then(res1 => {
      console.log('chenggong')
    }, err1 => {
      console.log('shibai')
    })
    if (localStorage.getItem('usermessage')) {
      VipRestricttender({ id: userid }).then(
        _res => {
          console.log('成功', _res)
          if (_res.code === 1) {
            next()
          } else {
            router.app.$message({
              message: _res.msg,
              type: 'warning'
            })
          }
        }, _err => {
          console.log('失败')
          next()
        }
      )
    } else {
      next({
        path: '/pc/loginroot/login'
      })
    }
  } else if (to.path.indexOf('/pc/content/scientificResearchProjectDetails') !== -1) {
    Projecthits({ id: userid }).then(res1 => {
      console.log('chenggong')
    }, err1 => {
      console.log('shibai')
    })
    if (localStorage.getItem('usermessage')) {
      VipRestrict({ id: userid }).then(
        _res => {
          console.log('成功', _res)
          if (_res.code === 1) {
            next()
          } else {
            router.app.$message({
              message: _res.msg,
              type: 'warning'
            })
          }
        }, _err => {
          console.log('失败')
          next()
        }
      )
    } else {
      next({
        path: '/pc/loginroot/login'
      })
    }
  } else if (to.path.indexOf('/pc/content/depthOfTheArticleDetails') !== -1) {
    Articlesthits({ id: userid }).then(res1 => {
      console.log('chenggong')
    }, err1 => {
      console.log('shibai')
    })
    // VipRestrict({ id: userid }).then(
    //   _res => {
    //     console.log('成功', _res.data.data)
    //     if (_res.data.data === 200) {
    //       next()
    //     } else {
    //       router.app.$options.$message({
    //         message: _res.data.test,
    //         type: 'warning'
    //       })
    //     }
    //   }, _err => {
    //     console.log('失败')
    //     next()
    //   }
    // )
    next()
  } else {
    next()
  }
})
export default router
